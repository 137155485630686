<template>
  <div class="text-center">
    <h4 class="mt-8">Please wait! Authentication in progress...</h4>
    <div style="width: 300px; margin: 0 auto;">
      <v-progress-linear class="my-4" indeterminate color="primary"></v-progress-linear>
    </div>
  </div>
</template>

<script>
import { instaToken } from '@/api/user'

export default {
  data () {
    return {
      // redirectUrl: window.location.href
    }
  },
  mounted () {
    this.updateToken()
  },
  methods: {
    async updateToken () {
      const params = new URLSearchParams(window.location.href.split('#')[1])
      const fbData = Object.fromEntries(params.entries())

      const submitData = {
        tokenExpireDate: fbData.data_access_expiration_time,
        accessToken: fbData.long_lived_token
      }
      console.log(submitData)
      const data = await instaToken(submitData)

      if (data.success) {
        this.$router.push('/profile/info')
      } else {
        this.$snackbar({
          message: data.message,
          color: 'error',
          timeout: 5000
        })
      }
    },
    async getAccessToken () {

      // const requestData = {
      //   redirectUrl: window.location.origin + '/auth/instagram',
      //   code: this.$route.query.code
      // }

      // const config = {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: 'Bearer ' + window.localStorage.getItem('user-token')
      //   }
      // }

      // const response = await axios.post('/api/instagram/access-token', requestData, config)

      // if (response.data.success) {
      //   this.$snackbar({
      //     message: response.data.message,
      //     timeout: 5000
      //   })
      //   this.$router.push('/profile/info')
      // } else {
      //   this.$snackbar({
      //     message: response.data.message,
      //     color: 'error',
      //     timeout: 5000
      //   })
      // }
    }
  }
}
</script>

<style scoped>
  h4 {
    font-weight: 500;
  }
</style>
